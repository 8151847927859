import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1520 3542 c0 -2 36 -49 80 -105 44 -56 80 -108 80 -115 0 -7 -61
-293 -136 -635 l-136 -622 18 -33 c34 -62 30 -62 489 -62 l415 0 -20 37 c-11
21 -20 48 -20 60 0 17 173 820 304 1411 l14 62 -104 0 c-57 0 -104 -3 -104 -6
0 -15 -271 -1248 -276 -1256 -7 -11 -262 -9 -269 2 -3 5 1 37 9 72 49 211 257
1181 254 1184 -5 4 -598 10 -598 6z"/>
<path d="M3542 2729 c-1 -8 -23 -40 -50 -70 -26 -29 -54 -63 -62 -73 -14 -18
-13 -19 2 -13 12 4 15 2 12 -7 -12 -30 19 -27 52 5 39 38 58 88 51 139 -2 19
-5 28 -5 19z"/>
<path d="M3567 2633 c-3 -15 -22 -47 -42 -70 -21 -25 -33 -48 -29 -55 5 -7 2
-9 -9 -5 -9 3 -21 -1 -29 -11 -12 -15 -11 -16 5 -3 10 8 17 9 17 3 0 -6 -4
-14 -10 -17 -5 -3 -10 -18 -10 -33 l1 -27 15 25 c11 18 14 19 9 5 -10 -31 75
54 91 92 16 36 18 91 5 111 -6 10 -10 6 -14 -15z"/>
<path d="M3007 2366 c-11 -28 -8 -105 4 -129 28 -54 116 -119 249 -183 74 -36
148 -73 164 -81 28 -15 28 -15 20 8 -4 13 -8 40 -7 61 l0 36 -152 76 c-163 82
-242 142 -260 196 -9 27 -13 30 -18 16z"/>
<path d="M2960 2254 c-38 -98 32 -185 235 -293 66 -35 133 -72 148 -83 23 -16
31 -17 43 -7 12 10 13 9 8 -5 -7 -19 22 -34 38 -18 8 8 5 9 -11 5 -24 -6 -26
-1 -11 28 8 14 7 19 -2 19 -6 0 -3 6 7 14 18 13 16 16 -35 48 -30 19 -104 61
-164 93 -128 70 -186 119 -216 180 -12 25 -24 45 -26 45 -3 0 -9 -12 -14 -26z
m450 -323 c0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9 6 15 23 19 23 5z"/>
<path d="M3445 2050 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M1740 1791 c-24 -46 0 -143 40 -156 30 -9 75 11 89 41 6 14 11 49 11
79 0 52 -1 55 -25 55 -23 0 -25 -4 -25 -47 0 -27 -4 -54 -9 -62 -16 -26 -31 0
-31 55 0 47 -3 54 -20 54 -10 0 -24 -9 -30 -19z"/>
<path d="M1929 1781 c-23 -23 -29 -39 -29 -70 0 -59 29 -85 84 -78 49 7 76 36
76 81 0 31 0 31 -45 28 -36 -3 -45 -8 -45 -22 0 -10 8 -21 18 -23 12 -4 11 -5
-6 -6 -12 0 -25 6 -28 14 -9 22 14 45 45 45 32 0 61 37 39 51 -29 19 -79 10
-109 -20z"/>
<path d="M2492 1801 c-9 -5 -12 -20 -9 -40 3 -17 1 -34 -4 -37 -6 -3 -6 -10
-1 -17 5 -7 7 -24 5 -39 -3 -26 -1 -28 32 -28 49 0 95 45 95 92 0 54 -74 97
-118 69z m103 -33 c5 4 5 1 1 -6 -6 -10 -11 -10 -22 -1 -8 6 -14 14 -14 16 0
3 6 0 13 -6 8 -6 18 -7 22 -3z m-27 -20 c13 -17 -3 -58 -23 -58 -8 0 -16 -8
-16 -17 -1 -17 -2 -17 -6 0 -2 9 -8 15 -12 13 -4 -3 -8 1 -8 9 0 8 4 12 9 9 5
-4 9 0 7 7 -4 33 2 49 20 49 10 0 23 -6 29 -12z m17 -67 c-3 -6 -14 -10 -23
-10 -15 1 -15 2 1 6 9 2 17 9 17 15 0 6 3 9 6 5 3 -3 3 -10 -1 -16z"/>
<path d="M2675 1791 c-3 -10 -3 -27 1 -37 5 -16 4 -16 -5 -4 -9 13 -11 13 -12
0 -1 -16 -4 -32 -15 -67 -5 -17 -2 -23 9 -23 9 0 19 5 22 10 3 6 12 10 18 10
7 0 6 -4 -3 -10 -12 -8 -10 -10 8 -10 13 0 31 -4 41 -9 26 -14 43 1 35 32 -3
15 -9 27 -13 28 -3 0 -14 2 -23 4 -10 2 -18 -3 -18 -11 0 -8 -9 -14 -21 -14
-14 0 -18 4 -12 13 8 12 9 17 12 40 1 9 3 9 9 -1 8 -12 52 -26 52 -17 0 3 -7
23 -16 45 -12 31 -22 40 -39 40 -14 0 -26 -7 -30 -19z"/>
<path d="M2868 1803 c-16 -2 -28 -9 -28 -14 0 -5 -5 -9 -11 -9 -5 0 -7 5 -4
10 3 6 1 10 -4 10 -6 0 -11 -3 -12 -7 0 -5 -2 -16 -4 -25 -4 -21 15 -24 33 -6
14 14 15 9 12 -64 -2 -57 4 -68 30 -61 17 5 20 14 20 63 0 53 2 59 23 62 14 2
23 11 25 26 4 23 -9 26 -80 15z m60 -28 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z"/>
<path d="M3024 1802 c-6 -4 -20 -41 -31 -84 -21 -75 -21 -76 -1 -81 11 -3 21
0 25 9 6 16 44 19 59 4 6 -6 18 -8 28 -4 13 5 16 13 11 33 -4 14 -10 28 -13
31 -4 3 -8 14 -9 26 -6 60 -36 89 -69 66z m47 -81 c7 11 9 11 9 -2 0 -19 -40
-33 -54 -19 -7 7 -6 10 2 10 7 0 12 8 12 18 1 15 2 15 11 -2 9 -15 13 -16 20
-5z m24 -51 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z"/>
<path d="M3390 1759 c0 -40 3 -47 18 -44 9 2 20 4 25 4 4 1 7 20 7 44 0 39 -2
43 -25 43 -23 0 -25 -4 -25 -47z"/>
<path d="M3480 1799 c0 -5 -4 -7 -10 -4 -6 4 -10 -25 -10 -79 0 -87 3 -94 30
-76 12 8 12 10 1 10 -8 0 -11 3 -8 6 4 4 12 2 19 -3 21 -16 47 -12 41 6 -5 13
-3 13 7 4 14 -13 43 -6 34 8 -3 5 -1 9 5 9 13 0 7 33 -8 38 -7 2 -9 15 -5 37
5 27 2 35 -14 44 -15 8 -22 8 -25 -2 -4 -9 -6 -9 -6 1 -1 15 -51 16 -51 1z
m58 -41 c-6 -16 -36 -34 -44 -26 -3 4 -4 14 -1 23 7 17 51 21 45 3z m-5 -79
c-16 -16 -57 -1 -44 15 6 7 7 17 3 21 -4 5 -2 5 5 1 6 -3 15 -1 19 6 5 8 11 5
17 -10 7 -16 7 -26 0 -33z"/>
<path d="M3168 1780 c-22 -17 -22 -19 -5 -37 9 -11 17 -23 17 -28 0 -5 -7 0
-15 11 -8 10 -15 14 -15 8 0 -6 7 -17 16 -25 13 -10 19 -10 36 0 14 9 18 9 13
1 -5 -9 -1 -10 16 -6 13 3 18 3 12 0 -7 -3 -13 -12 -13 -20 0 -8 -10 -14 -25
-14 -14 0 -25 -4 -25 -10 0 -5 8 -10 17 -10 9 0 14 -3 10 -6 -9 -9 -48 6 -42
17 4 5 1 9 -5 9 -6 0 -10 -8 -8 -17 5 -25 74 -30 99 -7 24 22 21 80 -4 78 -9
-1 -14 2 -12 7 3 4 -6 7 -19 6 -14 -1 -26 3 -28 8 -2 6 13 11 33 13 25 2 38 9
41 20 7 26 -64 27 -94 2z"/>
<path d="M3300 1749 c0 -37 3 -48 13 -43 8 5 7 2 -2 -9 -12 -15 -11 -20 3 -36
20 -22 80 -27 72 -6 -3 8 0 17 6 21 7 4 8 3 4 -4 -4 -7 -1 -12 7 -12 14 0 27
20 27 40 0 12 -44 3 -48 -10 -2 -6 -9 -7 -14 -4 -6 4 -17 -1 -25 -11 -7 -11
-13 -14 -13 -8 0 7 5 15 10 18 6 3 10 11 10 16 0 6 -3 8 -7 6 -5 -3 -6 17 -5
44 4 44 2 49 -17 49 -19 0 -21 -6 -21 -51z"/>
<path d="M2650 1650 c-25 -6 -25 -6 -3 -12 13 -3 26 -2 29 4 9 14 2 16 -26 8z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
